<template>
  <div class="">

    <div class="bottom-nav-holder">
      <div class="container">
        <div class="categories-dropdown d-flex align-items-center">

          <div class="wrapper">
            <div class="data-wrapper d-flex justify0content-center align-items-center btn-group">
              <div class="grid-icon">
                <font-awesome-icon icon="fa-solid fa-table-cells-large" size="xl" class="text-white" />
              </div>
              <b-dropdown id="dropdown-1" class="cat-drop" variant="link" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                  <span class="title text-white">{{
                    $t("profile.categories")
                  }}</span>
                  <div class="down-angle">
                    <font-awesome-icon icon="fa-solid fa-angle-down" size="xl" />
                  </div>
                </template>
                <b-dropdown-item v-for="(category, index) in categories" :key="index" @click="selectCategory(category)"
                  class="text-center d-inline-block mx-2 my-2 myDropDown-item">
                  <div class="img-cat-holder">
                    <img :src="category.icon_image_path" :alt="category.title" class="category-img" />
                  </div>
                  <div class="mx-0" v-if="category && category.title && category.title.length > 20" :title="category.title">{{ category.title.slice(0 , 20) }} ...</div>
                  <div class="mx-0" v-else>{{ category.title }}</div>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>

          <div class="other-catrgories" v-if="!mobile">
            <div class="middle">
              <navLinks />
            </div>
          </div>
          <div class=" d-flex justify-content-between align-items-center" v-if="mobile" :class="{'mobileView' : mobile}">
            <div class="cursor-pointer" v-if="mobile" @click="showMobileSearch">
              <font-awesome-icon icon="fa-solid fa-magnifying-glass" size="2x" />
            </div>
            <div class="">
              <!--Start Mbile Nav -->
              <div class="icon right-side">
                <div class="row justify-content-center align-items-center search-icon" v-if="mobile"></div>

                <font-awesome-icon v-b-toggle.sidebar-1 @click="toggleMobileNav" icon="fa-solid fa-bars"
                  :class="{ 'icon-active': mobileNav }" class="bar-icon" />
              </div>
              <transition name="mobile-nav">
                <b-sidebar :right="getDir === 'rtl'" v-if="mobileNav" @hidden="closeSideBar" id="sidebar-1" backdrop
                  width="300px" shadow z-index="3">
                  <MobileNav />
                </b-sidebar>
              </transition>
              <!--End Mbile Nav -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="" v-if="notHomePage && mobile">
      <NewSearch />
    </div>
  </div>
</template>

<script>
import categories from "@/services/categories";
import navLinks from "./navLinks.vue";
import MobileNav from "./MobileNav.vue";
import NewSearch from "@/components/pages/home/NewSearch.vue";
export default {
  methods: {
    /**
     * @vuese
     * toggle Mobile Nav function
     */
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    },
    /**
     * @vuese
     *  get all categories function
     */
    async getCategories() {
      await categories
        .getCategories("lists/categories")
        .then((resp) => {
          this.categories = resp.data.items;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    selectCategory(category) {
      this.$router.push(
        {
          path: `/categories/${category.id}`,
        },
        () => {
          this.$router.go(0);
        }
      );
    },
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 767) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },
    closeSideBar() {
      this.mobileNav = null;
    },
    showMobileSearch() {
      if (this.$route.path !== '/') {
        this.notHomePage = true
      }
      let searchDiv = document.querySelector('.search-component');
      if (searchDiv) {
        searchDiv.classList.toggle('d-none')
      }
    }
  },
  mounted() {
    this.getCategories();
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  data() {
    return {
      categories: null,
      mobile: null,
      mobileNav: null,
      windowWidth: null,
      notHomePage: false
    };
  },
  components: {
    navLinks,
    MobileNav,
    NewSearch
  },
};
</script>

<style lang="scss" scoped>
.bottom-nav-holder {
  background: #e2e2e2;

  .wrapper {
    background-color: $main-color;
    color: #fff;
    display: inline-block;
    padding: 5px 15px;
  }

  select,
  #dropdown-1 {
    padding: 0.7em 2rem;
    border-radius: 0.2em;
    border: none;
    color: #fff;

    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    background: none;
    font-size: 16px;
    position: relative;

    .down-angle {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      color: #fff;
    }
  }
}

.offer-link {
  transition: all 0.5s ease-in-out;

  &:hover {
    background-color: $main-color;
    color: #fff !important;
    padding: 15px;

    a {
      color: #fff !important;
    }
  }
}

.categories-dropdown {
  @media (max-width: 992px) {
    flex-direction: column;
  }
}

.other-catrgories {
  @media (max-width: 992px) {
    margin: 10px;
  }
}

.categories-dropdown {
  @media (max-width: 767px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.icon {
  display: flex;
  align-items: center;
  //position: absolute;
  height: 100%;

  svg {
    cursor: pointer;
    transition: 0.8s all ease-in-out;
    font-size: 25px;
  }

  .icon-active {
    transform: rotate(180deg);
  }
}



.category-img {
  width: 90%;

  height: 90%;
  object-fit: contain;
  // object-fit: cover;
  border-radius: 50%;

  @media(max-width:992px) {
    max-width: 65px;
    height: 65px;
    width: fit-content;
  }
}

.img-cat-holder {
  // padding: 10px;
  background: $categories-background;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: auto;
  border-radius: 50%;
  margin-bottom: 7px;
  transition: all .3s ease-in-out;
  // border: 4px solid transparent;
  margin-left: 0;

  // &:hover {
  //   border: 4px solid red;
  // }
}

.title {
  text-transform: uppercase !important;
  font-weight: bold !important;
}

.ar {
  .grid-icon {
    position: absolute;
    left: 10px;
  }
  .mobileView{
    .icon{
      display: block;
      margin-right: 30px;
    }
  }
}
.en {
  .mobileView{
    .icon{
      display: block;
      margin-left: 30px;
    }
  }
}

</style>
