<template>
  <!-- buyer user  account information page  -->
  <div class="account-information">
    <div class="row align-items-center">
      <div class="col-md-10 col-sm-12">
        <form
          @submit.prevent="updateProfile()"
          class="account-information-form"
        >
          <section class="user-info">
            <h4 class="main-header">{{ $t("profile.accountInfo") }}</h4>

            <b-row>
              <!-- First Name -->
              <b-col lg="4" v-if="buyerUserData.buyer_type !== 'Doctor'">
                <label for="f-name">{{ $t("register.department") }}</label>
                <span class="requried">*</span>
                <b-form-select v-model="form.job_title">
                  <b-form-select-option selected disabled value="null">{{
                    $t("register.selectDept")
                  }}</b-form-select-option>
                  <b-form-select-option
                    v-for="department in departments"
                    :key="department.id"
                    :value="department.title_en"
                  >
                    <span v-if="$i18n.locale == 'en'">{{
                      department.title_en
                    }}</span>
                    <span v-if="$i18n.locale == 'ar'">{{
                      department.title_ar
                    }}</span>
                  </b-form-select-option>
                </b-form-select>
                <div
                  class="error"
                  v-for="(error, index) in errors.job_title"
                  :key="index"
                >
                  {{ error }}
                </div>
              </b-col>
              <b-col lg="4">
                <b-form-group>
                  <label for="f-name">{{ $t("register.firstName") }}</label>
                  <span class="requried">*</span>
                  <div class="row justify-content-start align-items-center">
                    <div class="col-3" v-if="form.perfix">
                      <span>{{ form.perfix }}</span>
                    </div>
                    <div class="col-12">
                      <b-form-input id="f-name" v-model="form.first_name" />
                    </div>
                  </div>
                  <div
                    class="error"
                    v-for="(error, index) in errors.first_name"
                    :key="index"
                  >
                    {{ error }}
                  </div>
                </b-form-group>
              </b-col>
              <!-- Last Name -->
              <b-col lg="4">
                <b-form-group>
                  <label for="l-name">{{ $t("register.lastName") }}</label>
                  <span class="requried">*</span>
                  <b-form-input id="l-name" v-model="form.last_name" />
                  <div
                    class="error"
                    v-for="(error, index) in errors.last_name"
                    :key="index"
                  >
                    {{ error }}
                  </div>
                </b-form-group>
              </b-col>
              <!-- Email -->
              <b-col lg="6">
                <b-form-group>
                  <label for="email">{{ $t("register.email") }}</label>
                  <span class="requried">*</span>
                  <a @click="showEmailModal" class="mx-1 text-lowercase">
                    {{ $t("profile.needEmailContact") }}
                  </a>
                  <b-form-input id="email" v-model="form.email" disabled />
                  <div
                    class="error"
                    v-for="(error, index) in errors.email"
                    :key="index"
                  >
                    {{ error }}
                  </div>
                </b-form-group>
              </b-col>
              <!-- phone -->
              <b-col lg="6">
                <b-form-group>
                  <label for="phone"
                    >{{ $t("register.phone") }} (<span
                      ><b>{{ phonePrefix }}</b></span
                    >)</label
                  >
                  <span class="requried">*</span>
                  <a @click="showPhoneModal" class="mx-1 text-lowercase">
                    {{ $t("profile.needPhoneContact") }}
                  </a>
                  <div class="row justify-content-start align-items-center">
                    <div class="col-12">
                      <b-form-input
                        id="phone"
                        v-model="form.mobile_number"
                        disabled
                      />
                    </div>
                  </div>
                  <div
                    class="error"
                    v-for="(error, index) in errors.mobile_number"
                    :key="index"
                  >
                    {{ error }}
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </section>
          <section class="company-info">
            <div
              class="work-info my-5"
              v-if="
                (buyerUserData && buyerUserData.type === 'buyer') ||
                buyerUserData.type === 'b2b' ||
                (buyerUserData.type === 'supplier' &&
                  buyerUserData.is_buyer == 1)
              "
            >
              <h4
                class="main-header my-4"
                v-if="buyerUserData.buyer_type !== 'Doctor'"
              >
                {{ $t("profile.businessInformation") }}
              </h4>
              <b-row>
                <!-- Company Name -->
                <b-col lg="6" v-if="buyerUserData.buyer_type !== 'Doctor'">
                  <b-form-group>
                    <div class="row">
                      <div
                        :class="{
                          'col-md-6 col-sm-12': arabicAvailable !== 'no',
                          'col-12': arabicAvailable == 'no',
                        }"
                      >
                        <label for="companyName">{{
                          $t("register.englishCompanyName")
                        }}</label>
                        <span class="requried">*</span>
                        <b-form-input
                          id="companyName"
                          v-model="form.company_name_en"
                          disabled
                        />
                      </div>
                      <div
                        v-if="arabicAvailable !== 'no'"
                        :class="{
                          'col-md-6 col-sm-12': arabicAvailable !== 'no',
                          'col-12': arabicAvailable == 'no',
                        }"
                      >
                        <label for="companyName">{{
                          $t("register.arabicCompanyName")
                        }}</label>
                        <span class="requried">*</span>
                        <b-form-input
                          id="companyName"
                          v-model="form.company_name_ar"
                          disabled
                        />
                      </div>
                    </div>
                    <router-link to="/contact-us" class="mx-1 text-lowercase">
                      {{ $t("profile.needCompanyContact") }}
                    </router-link>
                    <div
                      class="error"
                      v-for="(error, index) in errors.company_name"
                      :key="index"
                    >
                      {{ error }}
                    </div>
                  </b-form-group>
                </b-col>
                <!-- Registration number -->
                <b-col lg="6">
                  <b-form-group>
                    <label for="RegistrationNumber">{{
                      $t("profile.RegistrationNumber")
                    }}</label>
                    <span class="requried">*</span>
                    <b-form-input
                      id="RegistrationNumber"
                      v-model="form.reg_number"
                    />
                    <div
                      class="error"
                      v-for="(error, index) in errors.reg_number"
                      :key="index"
                    >
                      {{ error }}
                    </div>
                  </b-form-group>
                </b-col>
                <!-- currentPosition -->
                <b-col lg="6">
                  <b-form-group>
                    <label for="l-name">{{
                      $t("login.currentPosition")
                    }}</label>
                    <span class="requried">*</span>
                    <div
                      class="error"
                      v-for="(error, index) in errors.job_title"
                      :key="index"
                    >
                      {{ error }}
                    </div>
                    <b-form-input id="f-name" v-model="form.job_title" />
                    <div
                      class="error"
                      v-for="(error, index) in errors.job_title"
                      :key="index"
                    >
                      {{ error }}
                    </div>
                  </b-form-group>
                </b-col>
                <!-- workplace -->
                <b-col lg="6" v-if="buyerUserData.buyer_type == 'Doctor'">
                  <b-form-group>
                    <label for="email">{{ $t("login.workplace") }}</label>
                    <span class="requried">*</span>
                    <b-form-input id="workplace" v-model="form.workplace" />
                    <div
                      class="error"
                      v-for="(error, index) in errors.workplace"
                      :key="index"
                    >
                      {{ error }}
                    </div>
                  </b-form-group>
                </b-col>
                <!-- nationality -->
                <b-col lg="6" v-if="buyerUserData.buyer_type == 'Doctor'">
                  <b-form-group>
                    <label for="email">{{ $t("login.nationality") }}</label>
                    <span class="requried">*</span>
                    <b-form-select v-model="form.nationality">
                      <b-form-select-option
                        v-for="(country, index) in countries"
                        :key="index"
                        :value="country.id"
                        >{{ country.title }}</b-form-select-option
                      >
                    </b-form-select>
                    <div
                      class="error"
                      v-for="(error, index) in errors.nationality"
                      :key="index"
                    >
                      {{ error }}
                    </div>
                  </b-form-group>
                </b-col>
                <!-- residency -->
                <b-col lg="6" v-if="buyerUserData.buyer_type == 'Doctor'">
                  <b-form-group>
                    <label for="email">{{ $t("login.residency") }}</label>
                    <span class="requried">*</span>
                    <b-form-select v-model="form.residency">
                      <b-form-select-option
                        v-for="(country, index) in countries"
                        :key="index"
                        :value="country.id"
                        >{{ country.title }}</b-form-select-option
                      >
                    </b-form-select>
                    <div
                      class="error"
                      v-for="(error, index) in errors.residency"
                      :key="index"
                    >
                      {{ error }}
                    </div>
                  </b-form-group>
                </b-col>
                <!-- specialty_id -->
                <b-col
                  :lg="!form.specialty_id ? '12' : '6'"
                  v-if="buyerUserData.buyer_type == 'Doctor'"
                >
                  <b-form-group>
                    <label for="l-name">{{ $t("login.specialty") }}</label>
                    <span class="requried">*</span>
                    <div
                      class="error"
                      v-for="(error, index) in errors.specialty_id"
                      :key="index"
                    >
                      {{ error }}
                    </div>
                    <b-form-select v-model="form.specialty_id">
                      <b-form-select-option
                        v-for="(department, index) in Allspecialty_id"
                        :key="index"
                        :value="department.id"
                      >
                        <span v-if="$i18n.locale == 'en'">{{
                          department.title_en
                        }}</span>
                        <span v-if="$i18n.locale == 'ar'">{{
                          department.title_ar
                        }}</span>
                      </b-form-select-option>
                    </b-form-select>
                    <div
                      class="error"
                      v-for="(error, index) in errors.job_title"
                      :key="index"
                    >
                      {{ error }}
                    </div>
                  </b-form-group>
                </b-col>
                <!-- sub specialty_id -->
                <b-col
                  lg="6"
                  v-if="
                    form.specialty_id && buyerUserData.buyer_type == 'Doctor'
                  "
                >
                  <b-form-group>
                    <label for="l-name">{{ $t("login.sub_specialty") }}</label>
                    <span class="requried">*</span>
                    <div
                      class="error"
                      v-for="(error, index) in errors.sub_specialty_id"
                      :key="index"
                    >
                      {{ error }}
                    </div>
                    <b-form-select v-model="form.sub_specialty_id">
                      <b-form-select-option
                        v-for="(department, index) in selected_sub_specialty_id"
                        :key="index"
                        :value="department.id"
                      >
                        <span v-if="$i18n.locale == 'en'">{{
                          department.title_en
                        }}</span>
                        <span v-if="$i18n.locale == 'ar'">{{
                          department.title_ar
                        }}</span>
                      </b-form-select-option>
                      <b-form-select-option value="other">{{
                        $t("login.other")
                      }}</b-form-select-option>
                    </b-form-select>
                    <div
                      class="error"
                      v-for="(error, index) in errors.job_title"
                      :key="index"
                    >
                      {{ error }}
                    </div>
                  </b-form-group>
                </b-col>
                <!-- other_sub_specialty -->
                <b-col
                  lg="12"
                  v-if="
                    form.sub_specialty_id == 'other' &&
                    buyerUserData.buyer_type == 'Doctor'
                  "
                >
                  <b-form-group>
                    <div>
                      <label for="f-name">{{
                        $t("login.other_sub_specialty")
                      }}</label>
                      <b-form-input
                        id="f-name"
                        v-model="form.other_sub_specialty"
                      />
                      <div
                        class="error"
                        v-for="(error, index) in errors.other_sub_specialty"
                        :key="index"
                      >
                        {{ error }}
                      </div>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row
                class="my-2"
                v-if="
                  buyerUserData &&
                  buyerUserData.active_subscription &&
                  buyerUserData.active_subscription.payment_method == 'boubyan'
                "
              >
                <b-col lg="12">
                  <div
                    class="form-check form-switch d-flex gap-2 align-items-center"
                  >
                    <input
                      class="form-check-input mb-0 pb-0"
                      type="checkbox"
                      id="auto_renew"
                      v-model="form.auto_renew"
                    />
                    <label
                      class="form-check-label mx-2 mb-0 pb-0"
                      for="auto_renew"
                    >
                      {{ $t("profile.autoRenew") }}
                    </label>
                  </div>
                </b-col>
               
              </b-row>
            </div>
            <div
              class="row"
              v-if="
                (buyerUserData && buyerUserData.type === 'buyer') ||
                buyerUserData.type === 'b2b' ||
                (buyerUserData.type === 'supplier' &&
                  buyerUserData.is_buyer == 1)
              "
            >
              <div class="col-lg-6 col-sm-12">
                <div class="company-logo">
                  <main class="mb-5">
                    <div
                      @dragover.prevent
                      @drop.prevent
                      class="data-holder mt-4"
                    >
                      <div @drop="handleFileDrop">
                        <p
                          class="h5"
                          v-if="buyerUserData.buyer_type == 'Doctor'"
                        >
                          {{ $t("profile.LicenseCertificateImage") }}
                        </p>
                        <p class="h5" v-else>{{ $t("profile.companyLogo") }}</p>
                        <br />
                        <div class="file-wrapper">
                          <input
                            type="file"
                            name="file-input"
                            @change="handleFileInput"
                          />
                          <div class="d-flex flex-column text-holder">
                            <p class="text-shown sign m-0 h2">+</p>
                            <p class="text-shown text h2">
                              {{ $t("profile.Upload") }}
                            </p>
                          </div>
                        </div>
                        <ul class="files mt-4">
                          <li v-for="(file, index) in files" :key="index">
                            <div class="file-data">
                              <span class="file-name">{{ file.name }}</span>
                              <span class="file-size">({{ file.size }} b)</span>
                              <button
                                @click="removeFile(index)"
                                class="border-none mx-2"
                                title="Remove"
                              >
                                X
                              </button>
                            </div>
                          </li>
                        </ul>
                        <img
                          v-if="CompanyImage"
                          :src="CompanyImage"
                          alt=""
                          srcset=""
                        />
                        <img
                          :src="buyerUserData.image_path"
                          alt="logo"
                          v-if="buyerUserData.image_path && !CompanyImage"
                        />
                      </div>
                      <div
                        class="error"
                        v-for="(error, index) in errors.logo"
                        :key="index"
                      >
                        {{ error }}
                      </div>
                    </div>
                  </main>
                </div>
              </div>
              <div
                class="col-lg-6 col-sm-12"
                v-if="
                  buyerUserData &&
                  buyerUserData.active_subscription &&
                  buyerUserData.active_subscription.payment_method == 'boubyan'
                "
              >
                <div class="plan">
                  <div class="plan-inner">
                    <div class="entry-title">
                      <h3
                        v-if="
                          buyerUserData.active_subscription.subscription
                            .name_en ||
                          buyerUserData.active_subscription.subscription.name_ar
                        "
                      >
                        {{
                          $i18n.locale == "en"
                            ? buyerUserData.active_subscription.subscription
                                .name_en
                            : buyerUserData.active_subscription.subscription
                                .name_ar
                        }}
                      </h3>
                      <div class="price">
                        <p>
                          {{
                            buyerUserData.active_subscription.subscription.cost
                              ? buyerUserData.active_subscription.subscription
                                  .cost
                              : "0"
                          }}
                        </p>
                        <p class="m-0" v-if="$i18n.locale == 'en'">USD</p>
                        <p class="m-0" v-else>دولار</p>
                      </div>
                    </div>
                    <div
                      class="d-flex w-100 h-100 flex-column justify-content-between align-items-center"
                    >
                      <div class="entry-content">
                        <ul>
                          <li>
                            <span
                              v-if="
                                $i18n.locale == 'en' &&
                                buyerUserData.active_subscription
                                  .subscription &&
                                buyerUserData.active_subscription.subscription
                                  .description_en
                              "
                              v-html="
                                buyerUserData.active_subscription.subscription
                                  .description_en
                              "
                            ></span>
                            <span
                              v-if="
                                $i18n.locale == 'ar' &&
                                buyerUserData.active_subscription.subscription
                                  .description_ar
                              "
                              v-html="
                                buyerUserData.active_subscription.subscription
                                  .description_ar
                              "
                            ></span>
                          </li>
                        </ul>
                        <div class="start_at">
                          <span>
                            {{ $t("profile.startedAt") }} :
                            {{ buyerUserData.active_subscription.start_at }}
                          </span>
                        </div>
                        <div class="start_at my-4">
                          <span>
                            {{ $t("profile.endAt") }} :
                            {{ buyerUserData.active_subscription.end_at }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <b-col lg="12">
                  <button
                    @click.prevent="downloadInvoice"
                    style="margin: 20px 0 0 0; padding: 10px 20px"
                  >
                    {{$t('profile.downloadPackageDetails')}}
                  </button>
                  <PlanInvoice
                    ref="invoiceComponent"
                  />
                </b-col>
              </div>
            </div>
          </section>

          <section class="settings">
            <h4 class="main-header">settings</h4>
            <div class="row flex-column">
              <!-- country_name  -->
              <b-col lg="4">
                <b-form-group>
                  <label for="country"
                    >{{ $t("profile.defaultCountry") }} :
                    {{ buyerUserData.country_name }}</label
                  >
                  <b-form-select v-model="form.country_id">
                    <b-form-select-option value="null" disabled
                      >{{ $t("profile.defaultCountry") }}
                      <span class="requried text-danger">*</span>
                    </b-form-select-option>
                    <b-form-select-option
                      v-for="(country, index) in countries"
                      :key="index"
                      :value="country.id"
                      >{{ country.title }}
                    </b-form-select-option>
                  </b-form-select>

                  <div
                    class="error"
                    v-for="(error, index) in errors.country"
                    :key="index"
                  >
                    {{ error }}
                  </div>
                </b-form-group>
              </b-col>
              <!-- currency_name  -->
              <b-col lg="4">
                <b-form-group>
                  <label for="country"
                    >{{ $t("profile.currency") }} :
                    {{ buyerUserData.currency_name }}
                  </label>
                  <b-form-select v-model="form.currency_id">
                    <b-form-select-option value="null" disabled selected
                      >{{ $t("profile.currency") }} ({{
                        buyerUserData.currency_name
                      }})
                      <span class="requried text-danger">*</span>
                    </b-form-select-option>
                    <b-form-select-option
                      v-for="(currency, index) in userStoredData.currencies"
                      :key="index"
                      :value="currency.id"
                      >{{ currency.code }}
                    </b-form-select-option>
                  </b-form-select>

                  <div
                    class="error"
                    v-for="(error, index) in errors.country"
                    :key="index"
                  >
                    {{ error }}
                  </div>
                </b-form-group>
              </b-col>
              <!-- language  -->
              <b-col lg="4">
                <b-form-group>
                  <label for="country"
                    >{{ $t("profile.lang") }} : {{ form.language }}</label
                  >
                  <b-form-select v-model="form.language">
                    <b-form-select-option value="null" disabled
                      >{{ $t("profile.selectLang") }}
                      <span class="requried text-danger">*</span>
                    </b-form-select-option>
                    <b-form-select-option value="ar">ar</b-form-select-option>
                    <b-form-select-option value="en">en</b-form-select-option>
                  </b-form-select>

                  <div
                    class="error"
                    v-for="(error, index) in errors.country"
                    :key="index"
                  >
                    {{ error }}
                  </div>
                </b-form-group>
              </b-col>
            </div>
          </section>

          <div class="d-flex align-items-center buttons-holder">
            <b-button type="submit" class="login-button">
              {{ $t("profile.save") }}
            </b-button>
            <b-button
              class="login-button border-main bg-transparent main-color mx-3"
              role="button"
              to="/profile/change-password"
            >
              <b>{{ $t("profile.changePassword") }}</b>
            </b-button>
          </div>
        </form>
      </div>
    </div>
    <b-modal ref="email-modal" hide-footer centered>
      <template #modal-header="{ close }">
        <h5>{{ $t("profile.emailVerify") }}</h5>
        <!-- Emulate built in modal header close button action -->
        <b-button
          size="sm"
          variant="outline-danger"
          @click="
            close();
            closeModal();
          "
        >
          x
        </b-button>
      </template>
      <div class="d-block text-center">
        <form action="">
          <b-form-group>
            <div
              class=""
              :class="{
                'text-left': $i18n.locale == 'en',
                'text-right': $i18n.locale == 'ar',
              }"
            >
              <label for="email">{{ $t("profile.newEmail") }}</label>
              <span class="requried">*</span>
            </div>
            <b-form-input id="newEmail" v-model="newForm.verify_email" />
            <div
              class="error"
              v-for="(error, index) in errors.verify_email"
              :key="index"
            >
              {{ error }}
            </div>
          </b-form-group>
          <b-form-group>
            <div
              class=""
              :class="{
                'text-left': $i18n.locale == 'en',
                'text-right': $i18n.locale == 'ar',
              }"
            >
              <label for="email">{{ $t("profile.oldPhone") }}</label>
              <span class="requried">*</span>
            </div>
            <b-form-input
              id="oldPhone"
              v-model="newForm.verify_mobile_number"
              type="number"
            />
            <div
              class="error"
              v-for="(error, index) in errors.verify_mobile_number"
              :key="index"
            >
              {{ error }}
            </div>
          </b-form-group>
        </form>
      </div>
      <div class="row justify-content-around align-items-center">
        <b-button
          class="mt-3"
          variant="outline-danger"
          @click="hideEmailModal"
          >{{ $t("cart.cancel") }}</b-button
        >
        <b-button class="mt-2" variant="outline-success" @click="goToVerify"
          >{{ $t("register.verify") }}
        </b-button>
      </div>
    </b-modal>
    <b-modal ref="phone-modal" hide-footer centered>
      <template #modal-header="{ close }">
        <h5>{{ $t("profile.phoneVerify") }}</h5>
        <!-- Emulate built in modal header close button action -->
        <b-button
          size="sm"
          variant="outline-danger"
          @click="
            close();
            closeModal();
          "
        >
          x
        </b-button>
      </template>
      <div class="d-block text-center">
        <form action="">
          <b-form-group>
            <div
              class=""
              :class="{
                'text-left': $i18n.locale == 'en',
                'text-right': $i18n.locale == 'ar',
              }"
            >
              <label for="email">{{ $t("profile.oldEmail") }}</label>
              <span class="requried">*</span>
            </div>
            <b-form-input id="oldEmail" v-model="newForm.verify_email" />
            <div
              class="error"
              v-for="(error, index) in errors.verify_email"
              :key="index"
            >
              {{ error }}
            </div>
          </b-form-group>
          <b-form-group>
            <div
              class=""
              :class="{
                'text-left': $i18n.locale == 'en',
                'text-right': $i18n.locale == 'ar',
              }"
            >
              <label for="email">{{ $t("profile.newPhone") }}</label>
              <span class="requried">*</span>
            </div>
            <b-form-input
              id="newPhone"
              v-model="newForm.verify_mobile_number"
              type="number"
            />
            <div
              class="error"
              v-for="(error, index) in errors.verify_mobile_number"
              :key="index"
            >
              {{ error }}
            </div>
          </b-form-group>
        </form>
      </div>
      <div class="row justify-content-around align-items-center">
        <b-button
          class="mt-3"
          variant="outline-danger"
          @click="hidePhoneModal"
          >{{ $t("cart.cancel") }}</b-button
        >
        <b-button class="mt-2" variant="outline-success" @click="goToVerify"
          >{{ $t("register.verify") }}
        </b-button>
      </div>
    </b-modal>
    <b-modal ref="check-modal" hide-footer centered>
      <div class="d-block text-center">
        <checkMailModal />
      </div>
      <div class="row justify-content-around align-items-center">
        <b-button
          class="mt-3"
          variant="outline-success"
          @click="hideCheckModal"
          >{{ $t("home.ok") }}</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
// b2b user  account information page
import auth from "@/services/auth";
import profile from "@/services/profile";
import checkMailModal from "@/components/changeprofileReply.vue";
import { createdFormData } from "@/services/helpers.js";
import PlanInvoice from "@/components/PlanInvoice.vue";
export default {
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        email: "",
        mobile_number: "",
        perfix: "",
        job_title: "",
        company_name_en: "",
        company_name_ar: "",
        reg_number: "",
        country_id: "",
        currency_id: "",
        language: "",
        specialty_id: null,
        sub_specialty_id: null,
        other_sub_specialty: null,
        workplace: null,
        residency: null,
        nationality: null,
        auto_renew: false,
      },
      newForm: {
        email: "",
        phone: "",
        callback_url: "",
        verify_email: "",
        verify_mobile_number: "",
      },
      countries: [],
      errors: {},
      phonePrefix: null,
      modalShow: false,
      files: [],
      CompanyImage: null,
      myFormControl: [],
      // departments: [
      //   {
      //     id: "Marketing",
      //     name_ar: "التسويق",
      //     name_en: "Marketing",
      //   },
      //   {
      //     id: "Sales",
      //     name_ar: "المبيعات",
      //     name_en: "Sales",
      //   },
      //   {
      //     id: "HR",
      //     name_ar: "الموارد البشرية",
      //     name_en: "HR",
      //   },
      //   {
      //     id: "Accounting",
      //     name_ar: "الحسابات",
      //     name_en: "Accounting",
      //   },
      //   {
      //     id: "IT",
      //     name_ar: "تكنولوجيا المعلومات",
      //     name_en: "IT",
      //   },
      //   {
      //     id: "Law",
      //     name_ar: "القانون",
      //     name_en: "Law",
      //   },
      // ],
      departments: [],
      Allspecialty_id: null,
      selected_sub_specialty_id: null,

      selectedDepartment: null,
    };
  },
  async mounted() {
    /**
     * check if country exist function  , else reload page
     * @vuese
     */

    //  check on country id
    let storedCountryId = localStorage.getItem("country");

    if (this.buyerUserData?.country_id) {
      this.form.country_id = this.buyerUserData?.country_id;
    } else if (this.userInfo?.item.country_id) {
      this.form.country_id = this.userInfo?.item.country_id;
    } else if (storedCountryId?.id) {
      this.form.country_id = storedCountryId?.id;
    }

    if (
      !this.buyerUserData?.country_id ||
      !this.userInfo?.item.country_id ||
      !storedCountryId.id
    ) {
      this.reloadPage();
    }

    /**
     * prepare callback_url to send it to backend with request
     * @vuese
     */

    this.newForm.callback_url = `${this.mainDoamin}otp-verification`;
    await this.checkProfileForm();

    await this.getAllCountires();
    await this.contactUsPhone();
    await this.getDepartments();
    await this.doctorRegisterDropDwon();

    if (this.buyerUserData?.active_subscription?.auto_renew) {
      if (this.buyerUserData?.active_subscription?.auto_renew == 1) {
        this.form.auto_renew = true;
      } else {
        this.form.auto_renew = false;
      }
    }
  },
  methods: {
    downloadInvoice() {
      this.$refs.invoiceComponent.downloadInvoice();
    },
    getDepartments() {
      profile.getDepartments().then((res) => {
        this.departments = res.data.items;
      });
    },
    getAllCountires() {
      auth
        .getAllCountires()
        .then((res) => {
          this.countries = res.data.items;
        })
        .then(() => {
          this.form.nationality = this.buyerUserData.nationality.id;
          this.form.residency = this.buyerUserData.residency.id;
        });
    },
    /**
     * @vuese
     *  checkProfileForm
     */

    checkProfileForm() {
      auth
        .checkProfileForm(this.buyerUserData)
        .then((res) => {
          let formControl = res.data.items;
          formControl.map((element) => {
            if (element.status !== 1) {
              this.myFormControl[element.input_key] = null;
            } else {
              this.myFormControl[element.input_key] = "";
            }
          });
        })
        .then(() => {
          /**
           * get AllCountires  function
           * @vuese
           */
          this.getAllCountires();
          /**
           * spread user data function ,  that comes from backend
           * @vuese
           */

          this.form = { ...this.buyerUserData };
          if (this.buyerUserData.company_name_ar == null) {
            this.form.company_name_ar = "";
          }
          if (this.buyerUserData.company_name_en == null) {
            this.form.company_name_en = "";
          }
          this.phonePrefix = this.buyerUserData.phone_prefix;
          this.form.mobile_number = this.buyerUserData.phone;

          this.form.language = this.buyerUserData.languagŽ
            ? this.buyerUserData.language
            : "en";
          this.form.currency_id = this.buyerUserData.currency_id
            ? this.buyerUserData.currency_id
            : "3";
          this.form.country_id = this.buyerUserData.country_id
            ? this.buyerUserData.country_id
            : this.userInfo.item.country_id;
        });
    },
    /**
     * Update Profile function
     * @vuese
     */
    updateProfile() {
      const payload = {
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        job_title: this.form.job_title,
        reg_number: this.form.reg_number,
        portal: "buyer",
        country_id: this.form.country_id,
        language: this.form.language,
        currency_id: this.form.currency_id,
        logo: this.files[0],
        auto_renew: this.form.auto_renew == true ? 1 : 0,
      };
      if (this.buyerUserData.buyer_type == "Doctor") {
        payload.specialty_id = this.form.specialty_id;
        payload.sub_specialty_id = this.form.sub_specialty_id;
        payload.other_sub_specialty = this.form.other_sub_specialty;
        payload.workplace = this.form.workplace;
        payload.residency = this.form.residency;
        payload.nationality = this.form.nationality;
      }
      auth
        .storeInfo(this.buyerUserData, createdFormData(payload))
        .then((res) => {
          this.sucessMsg(res.data.message);
          this.errors = {};
          this.$store.dispatch("getUserInfo");
          if (res.status == 200) {
            setTimeout(() => {
              this.$router.replace("/profile/adress-book");
            }, 500);
          }
        })
        .catch((error) => {
          const err = Object.values(error)[2].data;
          this.errors = err.items;
          this.errMsg(err.message);
        });
    },

    /**
     * reload Page function
     * @vuese
     */
    reloadPage() {
      if (localStorage.getItem("reloaded")) {
        // The page was just reloaded. Clear the value from local storage
        // so that it will reload the next time this page is visited.
        localStorage.removeItem("reloaded");
      } else {
        // Set a flag so that we know not to reload the page twice.
        localStorage.setItem("reloaded", "1");
        setTimeout(() => {
          location.reload();
        }, 1200);
      }
    },
    /**
     * show Email Modal function
     * @vuese
     */
    showEmailModal() {
      this.$refs["email-modal"].show();
    },
    /**
     * hide Email Modal function
     * @vuese
     */
    hideEmailModal() {
      this.$refs["email-modal"].hide();
      this.newForm = {};
      this.errors = {};
    },
    /**
     * show Phone Modal function
     * @vuese
     */
    showPhoneModal() {
      this.$refs["phone-modal"].show();
    },
    /**
     * hide Phone Modal function
     * @vuese
     */
    hidePhoneModal() {
      this.$refs["phone-modal"].hide();
      this.newForm = {};
      this.errors = {};
    },
    /**
     * show Check Modal function
     * @vuese
     */
    showCheckModal() {
      this.$refs["check-modal"].show();
    },
    /**
     * hide Check Modal function
     * @vuese
     */
    hideCheckModal() {
      this.$refs["check-modal"].hide();
      this.newForm = {};
      this.errors = {};
    },
    /**
     * go To Verify function to send data to backend to verify new changes
     * @vuese
     */
    goToVerify() {
      let data = {
        callback_url: this.newForm.callback_url,
        verify_mobile_number: this.newForm.verify_mobile_number,
        verify_email: this.newForm.verify_email,
        country_code: this.buyerUserData.country_code
          ? this.buyerUserData.country_code
          : this.userInfo.item.country_code,
      };
      profile
        .changeProfileEmailMobile(
          data,
          this.buyerUserData.type
            ? this.buyerUserData.type
            : this.userInfo.item.type
        )
        .then((res) => {
          this.sucessMsg(res.data.message);
          this.hideEmailModal();
          this.hidePhoneModal();
          if (res.data.message == "Success" || res.data.message == "تم بنجاح") {
            this.showCheckModal();
          }
        })
        .catch((error) => {
          if (error) {
            const err = Object.values(error)[2].data;
            this.errors = err.items;
            this.errMsg(err.message);
          }
        });
    },
    /**
     * close Modal used when click on close button to clear form data
     * @vuese
     */
    closeModal() {
      this.newForm = {};
      this.errors = {};
    },
    handleFileDrop(e) {
      let droppedFiles = e.dataTransfer.files[0];
      if (!droppedFiles) return;
      // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
      this.files = [];
      this.files.push(droppedFiles);
      let myFile = droppedFiles;
      this.CompanyImage = URL.createObjectURL(myFile);
    },
    handleFileInput(e) {
      let files = e.target.files[0];
      files = e.target.files[0];
      if (!files) return;
      // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
      this.files = [];
      this.files.push(files);
      let myFile = files;
      this.CompanyImage = URL.createObjectURL(myFile);
    },
    removeFile(fileKey) {
      this.files.splice(fileKey, 1);
      this.CompanyImage = null;
    },
    async doctorRegisterDropDwon() {
      try {
        const res = await auth.doctorRegisterDropDwon();
        if (res?.data?.items?.length) {
          this.Allspecialty_id = res.data.items;
        }
      } catch (error) {
        console.error("Error fetching specialties:", error);
      }
    },

    contactUsPhone() {
      profile.contactUsPhone().then((res) => {
        this.contactPhone = res.data.items;
      });
    },
  },
  computed: {
    /**
     * userStoredData
     * @vuese
     */
    userStoredData() {
      return JSON.parse(localStorage.getItem("country"));
    },
  },
  components: {
    checkMailModal,
    PlanInvoice,
  },
  metaInfo: {
    title: "buyer user  account information page",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: `${localStorage.getItem("lang")}`,
      amp: true,
    },
  },
  watch: {
    "form.specialty_id": {
      async handler(v) {
        if (v && v !== null) {
          if (this.Allspecialty_id && this.Allspecialty_id.length) {
            let element = this.Allspecialty_id.find((el) => el.id == v);
            if (element?.children?.length) {
              this.selected_sub_specialty_id = element.children;
              setTimeout(() => {
                this.form.sub_specialty_id =
                  this.buyerUserData.sub_specialty_id;
              }, 250);
            }
          } else {
            await this.doctorRegisterDropDwon(); // Await for the data to be fetched
            let element = this.Allspecialty_id.find((el) => el.id == v);
            if (element?.children?.length) {
              this.selected_sub_specialty_id = element.children;
              setTimeout(() => {
                this.form.sub_specialty_id =
                  this.buyerUserData.sub_specialty_id;
              }, 250);
            }
          }
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
/**
     * page style
     */
.account-information {
  padding-top: 40px;

  .main-header {
    text-transform: uppercase;
  }

  .account-information-form {
    padding: 20px 0;

    .login-button {
      margin: 30px 0px;
      width: 20%;
      @media (max-width: 992px) {
        width: 100% !important;
      }
      @media (min-width: 992px) and (max-width: 1500px) {
        width: 40% !important;
      }
    }
  }
}
@media (max-width: 992px) {
  .buttons-holder {
    flex-direction: column;
  }
}

html:lang(ar) {
  .account-information {
    text-align: right;
  }
}
.company-logo {
  main {
    margin-top: -30px;
    height: 100%;
  }

  .file-wrapper {
    text-align: center;
    height: 5em;
    vertical-align: middle;
    display: table-cell;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center; /* and other things to make it pretty */
  }

  .file-wrapper input {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
    font-size: 300px;
    height: 200px;
  }
  .data-holder {
    border: 2px solid $top-header-color;
    border-radius: 5px;
    color: #545454;
    padding: 20px;
  }
  .file-input {
    color: $top-header-color;
  }
  .text-holder {
    color: #bebebe;
    margin: 20px 0;
  }
}
#price {
  text-align: center;
}

.plan {
  @media (max-width: 767px) {
    display: block;
    margin-bottom: 5rem;
  }
}

.plan-inner {
  margin: 0 auto;
  min-width: 280px;
  max-width: 100%;
  position: relative;
  box-shadow: 0px 0px 4px 1px var(--main-color);
  border-radius: 5px;
}

.entry-title {
  background: $main-color;
  height: 140px;
  position: relative;
  text-align: center;
  color: #fff;
  margin-bottom: 30px;
}

.entry-title > h3 {
  background: $main-color;
  font-size: 20px;
  padding: 5px 0;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0;
  color: #fff;
  border-bottom: 2px solid;
}

.entry-title .price {
  position: absolute;
  bottom: -25px;
  background: $main-color;
  height: 95px;
  width: 95px;
  margin: 0 auto;
  left: 0;
  right: 0;
  overflow: hidden;
  border-radius: 50px;
  border: 5px solid #fff;
  line-height: 10px;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.price span {
  position: absolute;
  font-size: 9px;
  bottom: -10px;
  left: 30px;
  font-weight: 400;
}

.entry-content {
  color: #323232;
  height: 250px;
  overflow-y: auto;
  padding: 5px 10px;
}

.entry-content ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}

.entry-content li {
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 0;
}

.entry-content li:last-child {
  border: none;
}

.btn {
  padding: 15px;
  text-align: center;
}

.btn a {
  background: #323232;
  padding: 10px 30px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}

.ultimite .entry-title > h3 {
  background: #dd4b5e;
}

.ultimite .entry-title {
  background: #f75c70;
}

.ultimite .price {
  background: #dd4b5e;
}
.plan-text {
  border: 1px solid transparent;

  border-radius: 5px;
}
.active-tab {
  border-color: $main-color;
  color: $main-color;
}
.plan-text {
  padding: 10px 30px;
}

.payment-method {
  .methods-data {
    background: #ecf0f1;
    padding: 2rem;
    border-radius: 0.5rem;
    text-align: left;

    .info {
      border-bottom: 1px dashed #c5c6c6;
      padding: 1rem 0.3rem;
      color: #312620;
      font-weight: bold;
    }

    .total {
      padding: 1rem 0;
      color: #312620;
      font-weight: bold;

      .title {
        font-size: 14pt;
      }
    }

    .methods {
      background-color: #fff;
      border-radius: 0.5rem;
      border: 1px dashed #cfd0d0;

      .method {
        padding: 1rem;
        border-bottom: 1px dashed #cfd0d0;
        font-size: 11pt;
        color: #544842;

        .custom-radio {
          flex-wrap: wrap;
        }

        label {
          cursor: pointer;
        }

        span {
          width: 100%;
          font-size: 10pt;
          margin-top: -0.2rem;
          opacity: 0.7;
        }

        .online-media {
          img {
            object-fit: contain;
          }
        }
      }
    }
  }
}
.active-plan {
  color: #03d200;
}
</style>
