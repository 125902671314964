export default {
  needEmailContact: "To change email, click here.",
  // needEmailContact: "To change email, contact us.",
  needPhoneContact: "To change Mobile number, click here.",
  // needPhoneContact: "To change the phone, contact us.",
  needCompanyContact: "To change company, contact us.",
  //Header
  completeAccount: "Complete your account",
  completeMessage:
    "Please complete the registration to acquire full access to buyer license now you can only have limited access.",
  completeLink: "Click here to complete the registration",
  completeRate: "Completion rate of registration data",
  // side Menu
  myProfile: "My profile",
  shop: "Shop",
  shoppingCart: "Shopping Cart",
  accountInformation: "Account Information",
  buyerInformation: "Buyer Information",
  // categories
  welcomeAgain: "Welcome Again",
  categories: "Categories",
  // shopping card
  totalPrice: "Total Price",
  quantity: "Quantity",
  price: "Price",
  itemPrice: "Item Price",
  product: "Product",
  // Subscribe Newsletter
  subscribeNewsletter: "Subscribe to the newsletter",
  exclusiveOffers: "Be the first to be offered exclusive offers and coupons",
  notSubscribed: "I am not subscribed to the newsletter",
  subscribe: "Subscribe",
  // favorite
  favorite: "Favorite",
  b2bFav: "Standing Order",
  // changePassword
  changePassword: "Change Password",
  changePasswordMessage: "Your password has been updated successfully",
  currentPassword: "Current Password",
  NewPassword: "New Password",
  // adress book
  addressBook: "Address Book",
  shippingAddress: "Shipping Address",
  newAddress: "New address",
  country: "Country",
  countryOrigin: "Country of Origin",
  region: "Province",
  city: "City ",
  streetNumber: "Address",
  homeNumber: "Home Number",
  newStreetNumber: "Street",
  avenue: "Avenue",
  floor: "Floor",
  blockNumber: "Block Number",
  postCode: "Postal Code",
  zipCode: "Zip Code",
  note: "Notes",
  businessInformation: "Company Info",
  RegistrationNumber: "Registration Number",
  productReviews: "Product Reviews",
  construction: "Buying Date",
  evaluation: "Submit Date",
  review: "Review",
  actions: "Actions",
  viewDetails: "View Details",
  save: "Save",
  // Documents
  documents: "Documents",
  companyDocuments: "Company documents",
  saveData: "Save data",

  commercialLicense: "Company commercial license",
  signatureAccreditation: "Civi Id Copy of Authorised Signatory",
  commissionerCard: "Certificate of Certified signature ",
  certificateAdministration: "Right of Management certificate From MOC",

  establishmentContract: "Memorandum Of Association",
  letterAuthorization: "Signature Authority Document",

  ibanCertificate: "IBAN Certificate No",

  company: "Company",
  download: "Download",
  // Supplier Rating
  supplierRatings: "My supplier ratings",
  supplierRatingsEmpty: "You have not added any reviews",
  Construction: "Construction",
  Review: "Review",
  // Quotations
  quotations: "Quotations",
  definition: "Definition",
  displayName: "Display name",
  createdBy: "Created by",
  status: "Status",
  lastUpdate: "Last update",
  procedure: "Procedure",
  quotationsRatingsEmpty: "No records were found.",
  // BankNotifications
  bankNotifications: "Paid bank transfer notifications",
  bankNotificationsHeader: "Capture my payment by bank transfer",
  orderNumber: "order number",
  batch: "Batch",
  details: "Details",
  bankNotificationEmpty: "No request is sent to record payment",
  //Residents
  residents: "Residents",
  invoiceNo: "Invoice Number",
  supplier: "Supplier",
  residentsEmpty: "There are no residents",
  //SupplierCorrespondence
  supplierCorrespondence: "Supplier Messages",
  supplierCorrespondenceEmpty: "There are no contacts",
  cancelSubscribe: "cancel subscribe",
  delete: "delete",
  makeDefaultAddress: "make default address",
  //Orders Lists
  ordersLists: "My Orders",
  totalOrders: "Total Orders",
  totalQuotations: "Total Quotations",
  totalRefunds: "Total Refunds",

  ordersListsNew: "New Orders list",
  nameDescription: "Name and description",
  products: "Products",
  finalActivity: "Final activity",
  ordersListsEmpty: "There are no orders lists",
  //Gift Card
  giftCard: "Check Your Coupon",
  giftCardCoupon: "Enter a gift coupon",
  giftCardCheck: "Check status",
  profileData: "Your data is completed",
  completeInformation: "please complete your profile",
  filePlaceHolder: "Please Choose File",
  postcode: "Post Code",
  apartment: "Apartment",
  block_number: "Block Number",
  message: "Show Message",
  name: "Name",
  yourMessage: "Your message",
  date: "Date",
  productsNumber: "Number Of Products",
  amount: "Order Amount",
  suppliersNumber: "Supplier Name",
  paymentStatus: "Payment Status",
  buyMethod: "Payment Method",
  Actions: "Actions",
  printIno: "Purchase Order print",
  accountInfo: "User Info",
  customerName: "Customer Name",
  customerEmail: "Customer Email",
  tele: "Customer Phone",
  addressInfo: "Address Information",
  billingAddress: "billing Address",
  paymentInfo: "Payment Information",
  shoppingInfo: "Shipping Information",
  payment: "Payment",
  itemsOrder: "Items Order",
  itemStatus: "Item Status",
  originalPrice: "Original Price",
  qty: "Quantity",
  subTotal: "Sub-Total",
  discountPercent: "Discount Percent",
  rowTotal: "Total",
  orderTotal: "Order Total",
  notFound: "Sorry , Product Isn't Available Now",
  couponValid: "Coupon is valid",
  paymentType: "Payment Type",
  paymentCurency: "Payment Curency",
  deleiveryFees: "Delivery Fees : ",
  address: "Address",
  orderNote: "Note For this Order",
  productName: "Product Name",
  supplierOrder: "Supplier Order",
  return: "Return",
  serial: "Serial Number",
  cancel: "Cancel",
  view: "View",
  searchResult: "Search Result",
  searchNoResult: "No Results Found",
  validFor: "This Coupon is valid for",
  discountvalue: "Coupon discount value",
  validTill: "this coupon valid till",
  sentBy: "Sent by",
  quoteMessage: "Message",
  sentdate: "Sent Date",
  quoteData: "Quotations Details",
  title: "Product Name",
  expiry_at: "Expired At",
  send: "Send",
  offer: "Supplier Offer",
  sendMessage: "Send Message To Supplier",
  orderSerial: "Order Serial",
  pickedAddresses: "Picked Addresses",
  total_shipping_fee: "Total Shipping Fees",
  total_commission: "Total Commission",
  totalDiscount: "Total Discount",
  total_price: "Total Price",
  deliveryFeesText: "sorry , no shipping fees data available",
  noPickupData: "Sorry ,this supplier does not support pick-up",
  pay: "Pay",
  bankTransDocs: "Upload Bank Transfer Proof",
  orderBack: "Back to orders",
  productImage: "Product Image",

  supplierName: "Supplier Name",

  lastUpdateUser: "Last Update User",
  actuons: "Actions",
  corresponceDetail: "Buyer & Supplier Corresponding",
  notReviewd: "Not Rated Yet",
  aptNo: "Apartment",
  buildingNo: "Building",
  returnReason: "Return Reason",
  replace: "Replace",
  refund: "Refund",
  returnImage: "Product Image",
  refundMethods: "Refund Methods",
  replaceMethods: "Replace Request",
  returnRequests: "Return Requests",
  wallet: "My Wallet",
  returnOption: "Return Option",
  returnStatus: "Return Status",
  returnRequestDate: "Return Request Date",
  updatedAt: "Updated At",
  downloadImage: "Download Image",
  returnBack: "Back To Return Requests",
  returnSerial: "Return Serial",
  reshippingFees: "Reshipping Fees",
  balance: "Balance",
  receivables: "Receivables",
  payments: "Payments",
  RefundedValue: "Refunded Value",
  ValueDate: "Value Date",
  cancelReason: "Cancel Reason",
  createdAt: "Return Date",
  shippingMethod: "Shipping Method",
  billingInfo: "Billing Info",
  orderSug: "PURCHASE ORDER",
  shippingInfo: "Shipping Info",
  OrderDate: "Order Date",
  rejectionReason: "Rejection Reason",
  cancelReason1: "Unsuitable Fit",
  cancelReason2: "Unhappy with the product",
  cancelReason3: "Item is Damaged",
  cancelReason4: "Wrong Item was delivered",
  cancelReason5: "Unhappy with customer service",
  cancelReason6: "Duplicate order",
  cancelReason7: "Incorrect size",
  cancelReason8: "others",
  Notifications: "Notifications",
  ReturnedNumber: "Number of returned products",
  noNotifications: "No Notifications till now",
  justNow: "Just Now",
  second: "s",
  ago: "Ago",
  year: "Year",
  month: "Month",

  week: "Week",
  day: "Day",
  hour: "hour",
  minute: "minute",
  second2: "second",
  markRead: "mark as read",
  readAllNotifications: "Mark All Read",
  charge: "Deposite",
  enterValue: "Enter Charge Value",
  limit: "Limit",
  char: "Character",
  remain: "Remaining",
  lang: "default Language",
  currency: "default Currency",
  defaultCountry: "Default Country",
  newEmail: "New Email",
  oldEmail: "Old Email",
  newPhone: "New Phone",
  oldPhone: "Old Phone",
  emailVerify: "Email Verify",
  phoneVerify: "Phone Verify",
  newPhoneVerify: "Please Check Your Phone to Verify Your Account",
  selectLang: "Select Language",
  checkMail: "Check Your E-mail",
  wallet_visa: "Wallet + Visa",
  visaPayment: "Visa Payment",
  walletPayment: "Wallet Payment",
  standOrderName: "Standing Order List Name",
  selectExistStandOrder: "Select From Standing Order List",
  dashboard: "Dashboard",
  remainKnet: "The remaining amount can be paid using knet or card",

  aprovedProducts: "Approved Products",
  lowInvenity: "Low Inventory Products",
  bestSeller: "Best Seller",
  mostViewed: "Most Viewed Products",
  orders: "Order(s)",
  pending: "Pending",
  WalletRedirect: "You Will Be redirect to your wallet after 5 seconds",
  NotificationSettings: "Notification settings",
  others: "Others",
  sms: "SMS",
  Mail: "Mail",
  accountDetails: "Enter bank account Details",
  buy: "Buy",
  and: "And",
  buyXgetYOffer: "Buy X Get Y Offer",
  get: "Get",
  basketDeals: "Basket Deals",
  basketDeal: "Basket Deal",
  dailyOffers: "Daily Offerrs",
  monthlyOffers: "Monthly Offers",
  withdraw: "Redeem",
  enterwithdrawValue: "Ente Withdraw Value",
  withdrowStatus: "Withdraw Status",
  gift: "Gift",
  buyAndGetGift: "Buy And Get Gift",
  buXGetGift: "Buy X Get Gift",
  free: "Free",
  withdrowAmount: "Amount",
  bankData: "Bank Data",
  reason: "Reason",
  transferred: "Transferred",
  withdrowFile: "Withdraw File",
  basketName: "Basket Name",
  Account_number: "Account Number",
  iban: "Iban",
  bank_name: "Bank Name",
  frequently: "Frequently",
  lastOrder: "Last Order",
  upload: "Upload",
  lastMessage: "Last Message",
  hideMenu: "Hide Menu",
  responses: "Responses",
  pendingReturns: "Pending Returns",
  pendingStands: "Pending standing orders",
  selectDate: "Select Date",
  from: "From",
  to: "to",
  dateFrom: "Date From",
  dateTo: "Date to",
  resetDate: "Remove Range",
  searchAddress: "Search address...",
  search: "Search",
  deleteSelected: "Delete Selected",
  readSelected: "Read Selected",
  bulkAction: "Bulk Action",
  selectAll: "Select All",
  dateTime: "Date & Time",
  priceRange: "Price Range",
  priceFrom: "Price From",
  priceTo: "Price To",
  filtersApplied: "Filters Applied",
  perPage: "Show Me Per Page",
  companyLogo: "Company Logo",
  LicenseCertificateImage: "License/Certificate Image",
  Upload: "Upload",
  createdDate: "Created Date",
  charges: "Charges",
  moneyTransfer: "Money Transfer",
  monyAmount: "Amount",
  uploadDamageProducts: "Upload Image Of Damaged Products :",
  step2: "Step 2",
  step1: "Step 1",
  myCart: "My Cart",
  contactInfo: "Contact Info",
  clearAll: "Clear All",
  subtotal: "Sub-Total",
  returnDetails: "Return Request Details",
  requestType: "Request Type",
  branch: "Branch",
  uploadedImages: "Uploaded Images",
  printMemo: "Print Memo",
  productDetails: "Product Details",
  items: "Items",
  noReturnPolicy: "No Return Policy",
  specifyQuantity: "Specify quantities you would like to return for each item",
  Period: "Period",
  Ordered: "Ordered",
  Expired: "Expired",
  UpdatedBySupplier: "Updated By Supplier",
  Submitted: "Submitted",
  column: "columns",
  Pricing: "Pricing",
  PricingPlans: "Pricing Plans",
  selectRegion: "select Region",
  filter: "Filter",
  yearly: "Yearly",
  monthly: "Monthly",
  freePlan: "Free trial",
  noPlans: "Sorry , no plans yet",
  freePeripodMessage: "Start Your Free Trial For a",
  autoRenew:"Auto Renew",
  "startedAt":"Started At",
  "endAt":"End At",
  "userName":"User Name",
  "package":"Package Name",
  "description":"Description",
  "invoice":"Invoice",
  "packageDetails":"Package Details",
  "downloadPackageDetails":"Download Package Details",
  "Subscriptions":"Subscriptions",
  "paymentMethod":"Payment Method",
  "cardNumber":"Card Number",
  "startFreeTrial":"Start Free Trial"
};
