<template>
  <div class="subscriptions-wrapper">
    <div class="container">
      <div class="text-center my-5">
        <h1>
          {{ $t("profile.subscriptions") }}
        </h1>
      </div>
      <div
        class="filters my-5 w-100 d-flex justify-content-end"
        v-if="userSubscriptions && userSubscriptions.length"
      >
        <div class="date-picker-wrapper">
          <input
            type="date"
            id="start"
            name="trip-start"
            class="styled-date-picker"
            v-model="date"
            @focus="removePlaceholder"
            @blur="restorePlaceholder"
            :placeholder="placeholder"
          />
        </div>
      </div>
      <!-- table contain favorite data  -->
      <div
        class="userSubscriptions-table"
        v-if="userSubscriptions && userSubscriptions.length && !loading"
      >
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>{{ $t("profile.startedAt") }}</th>
              <th>{{ $t("profile.endAt") }}</th>
              <th>{{ $t("profile.paymentMethod") }}</th>
              <th>{{ $t("profile.name") }}</th>
              <th>{{ $t("profile.cardNumber") }}</th>
              <th>{{ $t("items.price") }}</th>
              <th>{{ $t("profile.Period") }}</th>
              <th>{{ $t("profile.actions") }}</th>

              <!-- name , price , period  -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(subscription, index) in userSubscriptions" :key="index">
              <td class="text-center">
                <div class="d-block text-center" v-if="subscription.start_at">
                  {{ subscription.start_at }}
                </div>
                <div class="d-block text-center" v-else>-</div>
              </td>
              <td class="text-center">
                <div class="d-block text-center" v-if="subscription.end_at">
                  {{ subscription.end_at }}
                </div>
                <div class="d-block text-center" v-else>-</div>
              </td>
              <td class="text-center">
                <div
                  class="d-block text-center"
                  v-if="subscription.payment_method"
                >
                  {{ subscription.payment_method }}
                </div>
                <div class="d-block text-center" v-else>-</div>
              </td>
              <td class="text-center">
                <div
                  class="d-block text-center"
                  v-if="
                    subscription.subscription.name_en ||
                    subscription.subscription.name_ar
                  "
                >
                  <span
                    v-if="
                      $i18n.locale == 'en' && subscription.subscription.name_en
                    "
                  >
                    {{ subscription.subscription.name_en }}
                  </span>
                  <span
                    v-if="
                      $i18n.locale == 'ar' && subscription.subscription.name_ar
                    "
                  >
                    {{ subscription.subscription.name_ar }}
                  </span>
                </div>
                <div class="d-block text-center" v-else>-</div>
              </td>
              <td class="text-center">
                <div
                  class="d-block text-center"
                  v-if="subscription.card_number"
                >
                  {{ subscription.card_number }}
                </div>
                <div class="d-block text-center" v-else>-</div>
              </td>
              <td class="text-center">
                <div
                  class="d-block text-center"
                  v-if="subscription.subscription.cost"
                >
                  <span v-if="subscription.subscription.cost">
                    {{ subscription.subscription.cost }}
                    <span class="">
                      <span class="m-0" v-if="$i18n.locale == 'en'">USD</span>
                      <span class="m-0" v-else>دولار</span>
                    </span>
                  </span>
                </div>
                <div class="d-block text-center" v-else>-</div>
              </td>
              <td class="text-center">
                <div
                  class="d-block text-center"
                  v-if="subscription.subscription.type"
                >
                  <span v-if="subscription.subscription.type">
                    {{ subscription.subscription.type }}
                  </span>
                </div>
                <div class="d-block text-center" v-else>-</div>
              </td>
              <td class="text-center">
                <b-button
                  variant="outline-light main-color border-main"
                  class="m-2"
                  @click="downloadInvoice(subscription)"
                >
                  <font-awesome-icon icon="fa-solid fa-download" />
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- <div
          class="text-center d-flex justify-content-start align-items-center mt-5"
        >
          
          <Paginate
            v-if="userSubscriptions"
            :total-pages="totalPages"
            :per-page="totalPages"
            :current-page="page"
            @pagechanged="onPageChange"
          />
        </div> -->
      </div>
      <div class="text-center" v-else-if="!loading && !userSubscriptions">
        <h2>{{ $t("home.noData") }}</h2>
      </div>
      <div class="loading text-center" v-if="loading">
        <span>
          <b-spinner label="Spinning" small></b-spinner>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import profile from "@/services/profile";
// import Paginate from "@/components/global/Paginate.vue";
import jsPDF from "jspdf";
export default {
  methods: {
    downloadInvoice(subscription) {
      const doc = new jsPDF();

      // Add title to the PDF
      doc.setFontSize(16);
      doc.text("Subscription Invoice", 105, 20, { align: "center" });

      // Add subscription details
      const data = [
        { label: "Start Date", value: subscription.start_at || "-" },
        { label: "End Date", value: subscription.end_at || "-" },
        { label: "Payment Method", value: subscription.payment_method || "-" },
        {
          label: "Name",
          value:
            subscription.subscription.name_en ||
            subscription.subscription.name_ar ||
            "-",
        },
        { label: "Card Number", value: subscription.card_number || "-" },
        {
          label: "Cost",
          value: subscription.subscription.cost
            ? `${subscription.subscription.cost} USD`
            : "-",
        },
        { label: "Period", value: subscription.subscription.type || "-" },
      ];

      let y = 40; // Initial y-coordinate
      data.forEach((item) => {
        doc.setFontSize(12);
        doc.text(`${item.label}:`, 20, y);
        doc.text(item.value, 80, y);
        y += 10;
      });

      // Save the PDF
      doc.save(
        `Invoice-${subscription.subscription.name_en || "Subscription"}.pdf`
      );
    },
    getSubscribedPlans(date) {
      this.loading = true;
      profile
        .getSubscribedPlans({ date })
        .then((resp) => {
          this.userSubscriptions = resp.data.items.subscription;

          //   this.total = resp.data.items.total;
          //   this.totalPages = Math.ceil(
          //     resp.data.items.total / resp.data.items.per_page
          //   );

          //   this.totalRecords = resp.data.items.total;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onPageChange(page) {
      this.page = page;
      this.getSubscribedPlans();
    },
    /**
     * function for pagination
     * @vuese
     */
    onChangeRecordsPerPage() {
      this.getSubscribedPlans();
    },
    /**
     * function for pagination
     * @vuese
     */
    gotoPage() {
      if (!isNaN(parseInt(this.enterpageno))) {
        this.page = parseInt(this.enterpageno);
        this.getSubscribedPlans();
      }
    },
    removePlaceholder() {
      this.placeholder = "";
    },
    restorePlaceholder() {
      if (!this.date) {
        this.placeholder = "Select a date";
      }
    },
  },
  mounted() {
    this.getSubscribedPlans();
  },
  data() {
    return {
      loading: false,
      perPage: 5,
      total: 0,
      currentPage: 1,

      page: 1,
      totalPages: 0,
      totalRecords: 0,
      recordsPerPage: 10,
      enterpageno: "",
      userSubscriptions: null,
      date: "",
      placeholder: "Select a date", // Default placeholder text
    };
  },
  components: {
    // Paginate,
  },
  watch: {
    date(newVal) {
      this.date = newVal;
      this.getSubscribedPlans(newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 767px) {
  table {
    text-align: center;

    tbody {
      tr {
        margin: 30px 0;
      }
    }
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
    padding: 0;
  }

  table td {
    display: block;
    font-size: 0.8rem;
    border-top: none !important;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    margin: 30px 0;
    display: block;
  }

  .actions {
    justify-content: center;
    align-items: center;
  }
}

.product-image {
  border-radius: 0.5rem;
}
.price {
  font-weight: bold;
}
h1 {
  color: #000;
}

.date-picker-wrapper {
  position: relative;
  display: inline-block;
  width: 200px;
}

.styled-date-picker {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  color: #333;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.styled-date-picker:focus {
  border-color: #007bff;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.25);
}

.styled-date-picker::-webkit-calendar-picker-indicator {
  filter: invert(0.5);
}

.styled-date-picker::placeholder {
  color: #999;
  font-style: italic;
}
</style>
